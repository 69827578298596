/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";
import { ErrorMessage } from "./shared.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
`;

export const Game = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });
  let [drawCount, setDrawCount] = useState(0);
  let [target, setTarget] = useState({ cards: [], src: "", dst: "" });
  let [error, setError] = useState("");

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);
      const data = await response.json();
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
      setDrawCount(data.drawCount);
    };
    getGameState();
  }, [id]);

  useEffect(() => {
    if (target.src && target.dst && target.cards.length > 0) {
      console.log("Requested move:", target);
      executeMove();
    }
  }, [target]);

  const selectCards = (pileName, cardId) => {
    const pile = state[pileName];
    if (pile.length === 0) {
      setError("Error: Cannot first click empty pile");
      setTarget({ cards: [], src: "", dst: "" });
      return [];
    }

    const cardIndex = pile.findIndex(
      (card) => `${card.suit}:${card.value}` === cardId
    );

    if (!pile[cardIndex].up) {
      setError("Error: Cannot click face down card");
      setTarget({ cards: [], src: "", dst: "" });
      return [];
    }

    return pile.slice(cardIndex);
  };

  const selectDraw = (pileName) => {
    const numberOfCardsToDraw = Math.min(drawCount, state[pileName].length);
    const newDrawPile = [...state[pileName]];
    const newDiscardPile = [...state.discard];

    const drawnCards = newDrawPile.splice(-numberOfCardsToDraw);
    const faceUpDrawnCards = drawnCards.map((card) => ({ ...card, up: true }));
    newDiscardPile.push(...faceUpDrawnCards.reverse());

    return faceUpDrawnCards;
  };

  const onClick = (ev, pileName) => {
    const cardId = ev.target.id;

    if (target.src && target.src !== "draw" && pileName === "draw") {
      setError("Error: Cannot move back to draw pile");
      setTarget({ cards: [], src: "", dst: "" });
      return;
    }

    if (pileName === "draw" && state.draw.length === 0) {
      const newDrawPile = [...state.discard]
        .reverse()
        .map((card) => ({ ...card, up: false }));
      setTarget({ cards: newDrawPile, src: "discard", dst: "draw" });
    } else if (pileName === "draw" && state.draw.length > 0) {
      const selectedCards = selectDraw(pileName);
      if (selectedCards.length > 0) {
        setTarget({ cards: selectedCards, src: pileName, dst: "discard" });
      }
    } else if (!target.src) {
      // Select cards
      const selectedCards = selectCards(pileName, cardId);
      if (selectedCards.length > 0) {
        setTarget({ ...target, cards: selectedCards, src: pileName });
      }
    } else if (target.src && !target.dst) {
      setTarget({ ...target, dst: pileName });
    }
  };

  const executeMove = async () => {
    if (!target.src || !target.dst || target.cards.length === 0) {
      setError("Error: Invalid move attempt");
      return;
    }

    if (target.src === target.dst) {
      setError("Error: Source and destination cannot be the same");
      setTarget({ cards: [], src: "", dst: "" });
      return;
    }

    const move = {
      cards: target.cards,
      src: target.src,
      dst: target.dst,
    };

    try {
      const response = await fetch(`/v1/game/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(move),
      });

      const result = await response.json();

      if (response.ok) {
        setState(result.state);
        setError("");
      } else {
        setError("Move validation failed: " + result.error);
      }
    } catch (error) {
      setError("Error executing move: " + error.message);
    } finally {
      setTarget({ cards: [], src: "", dst: "" });
    }
  };

  const createPileOnClickHandler = (pileName) => {
    return (ev) => {
      ev.stopPropagation();
      onClick(ev, pileName);
    };
  };

  const handleAutocomplete = async () => {
    try {
      const response = await fetch(`/v1/game/${id}/autocomplete`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result.success) {
        setState(result.state);
      } else {
        console.log("No valid move found");
      }
    } catch (error) {
      setError("Error requesting autocomplete: " + error.message);
    }
  };

  const goToResultsPage = () => {
    navigate(`/results/${id}`);
  };

  return (
    <GameBase onClick={() => setTarget({ cards: [], src: "", dst: "" })}>
      <ErrorMessage msg={error} hide={!error} />
      <button onClick={handleAutocomplete}>Autocomplete</button>
      <button onClick={goToResultsPage}>Go to Results</button>
      <CardRow>
        <Pile
          cards={state.stack1}
          spacing={0}
          pileName="stack1"
          onClick={createPileOnClickHandler("stack1")}
        />
        <Pile
          cards={state.stack2}
          spacing={0}
          pileName="stack2"
          onClick={createPileOnClickHandler("stack2")}
        />
        <Pile
          cards={state.stack3}
          spacing={0}
          pileName="stack3"
          onClick={createPileOnClickHandler("stack3")}
        />
        <Pile
          cards={state.stack4}
          spacing={0}
          pileName="stack4"
          onClick={createPileOnClickHandler("stack4")}
        />
        <CardRowGap />
        <Pile
          cards={state.draw}
          spacing={0}
          pileName="draw"
          onClick={createPileOnClickHandler("draw")}
        />
        <Pile
          cards={state.discard}
          spacing={0}
          pileName="discard"
          onClick={createPileOnClickHandler("discard")}
        />
      </CardRow>
      <CardRow>
        <Pile
          cards={state.pile1}
          pileName="pile1"
          onClick={createPileOnClickHandler("pile1")}
        />
        <Pile
          cards={state.pile2}
          pileName="pile2"
          onClick={createPileOnClickHandler("pile2")}
        />
        <Pile
          cards={state.pile3}
          pileName="pile3"
          onClick={createPileOnClickHandler("pile3")}
        />
        <Pile
          cards={state.pile4}
          pileName="pile4"
          onClick={createPileOnClickHandler("pile4")}
        />
        <Pile
          cards={state.pile5}
          pileName="pile5"
          onClick={createPileOnClickHandler("pile5")}
        />
        <Pile
          cards={state.pile6}
          pileName="pile6"
          onClick={createPileOnClickHandler("pile6")}
        />
        <Pile
          cards={state.pile7}
          pileName="pile7"
          onClick={createPileOnClickHandler("pile7")}
        />
      </CardRow>
    </GameBase>
  );
};

Game.propTypes = {};
