import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormInput,
  FormLabel,
  FormButton,
} from "./shared.js";

export const Edit = ({ username }) => {
  let navigate = useNavigate();
  let [state, setState] = useState({
    first_name: "",
    last_name: "",
    city: "",
  });
  let [error, setError] = useState("");

  const onChange = (ev) => {
    setError("");
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
    });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    const res = await fetch(`/v1/user`, {
      method: "PUT",
      body: JSON.stringify(state),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      navigate(`/profile/${username}`); // Redirect to the updated profile page
    } else {
      const err = await res.json();
      setError(err.error);
    }
  };

  return (
    <div style={{ gridArea: "main" }}>
      <ErrorMessage msg={error} />
      <FormBase onSubmit={onSubmit}>
        <FormLabel htmlFor="first_name">First Name:</FormLabel>
        <FormInput
          id="first_name"
          name="first_name"
          placeholder="First Name"
          onChange={onChange}
          value={state.first_name}
          required
        />

        <FormLabel htmlFor="last_name">Last Name:</FormLabel>
        <FormInput
          id="last_name"
          name="last_name"
          placeholder="Last Name"
          onChange={onChange}
          value={state.last_name}
          required
        />

        <FormLabel htmlFor="city">City:</FormLabel>
        <FormInput
          id="city"
          name="city"
          placeholder="City"
          onChange={onChange}
          value={state.city}
          required
        />
        <div />
        <FormButton id="submitBtn" type="submit">
          Save Changes
        </FormButton>
      </FormBase>
    </div>
  );
};
